import React, { useState } from "react"

import Modal from "react-modal"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

Modal.setAppElement(`#___gatsby`)

const Video = ({ videoSrcURL, videoTitle }) => {
  const [videoIsOpen, setVideoIsOpen] = useState(false)

  var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const videoId = videoSrcURL.match(regExp)[2]

  const videoThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`

  const openModal = () => {
    setVideoIsOpen(true)
  }

  const closeModal = () => {
    setVideoIsOpen(false)
  }

  return (
    <div className="video-thumbnail" style={{ cursor: "pointer" }}>
      <button
        className="play-button"
        onClick={openModal}
        aria-label="Open youtube video"
      />
      <img
        src={videoThumbnail}
        alt={videoTitle}
        onClick={openModal}
        onKeyPress={openModal}
        role="presentation"
      />
      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          youtube={{
            autoplay: 1,
            mute: 1,
          }}
          isOpen={videoIsOpen}
          videoId={videoId}
          onClose={closeModal}
        />
      )}
    </div>
  )
}
export default Video
