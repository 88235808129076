import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
// import { GatsbyImage } from "gatsby-plugin-image"

const VideoTemplate = ({ data }) => {
  const { frontmatter, body } = data.mdx
  const { title, link } = frontmatter

  return (
    <Layout>
      <Seo title={title} />
      <div className="shell">
        <div className="video-post__wrapper">
          <h1 className="video-post__title">{title}</h1>
          <div className="video-post__featured-image">
            <Video videoSrcURL={link} videoTitle={title} />
          </div>
          <div className="video-post__text mdx__body ">
            {data.mdx ? (
              <MDXRenderer>{body}</MDXRenderer>
            ) : (
              <div>This page hasn't been translated yet</div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default VideoTemplate

export const query = graphql`
  query ($locale: String!, $slug: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        slug
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        link
      }
      body
    }
  }
`
